import React from 'react';

const index = () => {
  return (
    <div className="h-[100dvh] w-[100dvw]">
      <iframe
        title="Embedded Page"
        src="https://qift.viewpage.co/IBD-2024"
        style={{ width: '100%', height: '100%' }}
      ></iframe>
    </div>
  );
};

export default index;
